import React from "react"
import { Link } from "gatsby"
import { Box, Button, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"
import ProjeyeGorePaslanmazFiltre from "../components/global/gatsby-image/projeyegorepaslanmazfiltre"
import PaslanmazFiltreGaleri from "../components/global/carousel/paslanmazfiltregaleri"
import Seo from "../components/global/seo/seo.jsx"
const EmisFiltresi = () => {
  return (
    <Layout>
      <Seo
        title="Emiş Filtresi - Paslanmaz Filtre Erez Kaynak " 
        description="Paslanmaz Emiş Filtresi Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz emiş filtresi imalatı yapmaktadır.Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz emiş"
        keywords='filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek,emiş filtresi,emiş filtreleri,1" emiş filtresi,2" emiş filtresi,parmak emiş filtre,parmak emiş filtresi,emiş için filtre,emiş filtre istanbul,paslanmaz emiş filtresi,paslanmaz emiş filtre imalatı,emiş filtresi kullanım alanları,emiş filtresi nedir,emiş filtrecisi'
        url="emis-filtresi"
      />
      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <Typography component="h1" variant="h4">
              <Box textAlign="center" fontWeight={500} mt={3}>
                Paslanmaz Emiş Filtresi İmalatı
              </Box>
            </Typography>
            <Lead>
              Firmamız proje çizimine yada numuneye göre müşterilerimizin
              istekleri doğrultusunda <span>paslanmaz emiş filtresi</span>{" "}
              yapmaktadır.
            </Lead>
            <Lead>
              Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak
              istediğiniz ölçü, numune ya da projeye göre{" "}
              <span>paslanmaz emiş filtresi imalatını</span>{" "}
              gerçekleştirmekteyiz.
            </Lead>
            <Lead>
              Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
            </Lead>
            <Box textAlign="center" pb={3}>
              <Link to="/iletisim" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="secondary">
                  İLETİŞİM BİLGİLERİ
                </Button>
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <PaslanmazFiltreGaleri />
      </Box>
      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              align="center"
              title="Paslanmaz Emiş Filtresi Kullanım Alanları"
            />
            <br />
            <ProjeyeGorePaslanmazFiltre
              elevation
              imgUrl="filtre2fluid"
              imgAlt="Projeye Göre Paslanmaz Filtre"
            />
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <FiltreGaleri />
      </Box>
    </Layout>
  )
}
export default EmisFiltresi
